<template>
	<div>
		<div class="box box_mm">
			<div class="box_main">
				<div
					style="height: 70vh;overflow-y: auto;padding-right: 0.5rem;padding-bottom: 40px;box-sizing: border-box;">
					<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="110px" class="demo-ruleForm">
						<el-form-item label="会议标题" prop="name">
							<el-input v-model="ruleForm.name"></el-input>
						</el-form-item>
						<el-form-item label="调研活动" prop="invest_research_id">
							<el-select v-model="ruleForm.invest_research_id" placeholder="请选择会议议题" style="width:475px;"
								clearable>
								<el-option :label="item.name" :value="item.id" v-for="(item,index) in hyytlist"
									:key="index"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="报名时间" required>
							<div style="display: flex;align-items: center;">
								<el-form-item prop="kstime">
									<el-date-picker v-model="ruleForm.kstime" type="datetime"
										value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择报名开始时间">
									</el-date-picker>
								</el-form-item>
								<div style="margin: 0 10px;">至</div>
								<el-form-item prop="endtime">
									<el-date-picker v-model="ruleForm.endtime" type="datetime"
										value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择报名结束时间">
									</el-date-picker>
								</el-form-item>
							</div>
						</el-form-item>
						<el-form-item label="会议时间" required>
							<div style="display: flex;align-items: center;">
								<el-form-item prop="addtime">
									<el-date-picker v-model="ruleForm.addtime" type="datetime"
										value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择会议开始时间">
									</el-date-picker>
								</el-form-item>
								<div style="margin: 0 10px;">至</div>
								<el-form-item prop="dqtime">
									<el-date-picker v-model="ruleForm.dqtime" type="datetime"
										value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择会议结束时间">
									</el-date-picker>
								</el-form-item>
							</div>
						</el-form-item>
						<el-form-item label="签到时间" required="">
							<div style="display: flex;align-items: center;">
								<el-form-item prop="qdtime">
									<el-date-picker v-model="ruleForm.qdtime" type="datetime"
										value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择签到开始时间">
									</el-date-picker>
								</el-form-item>
								<div style="margin: 0 10px;">至</div>
								<el-form-item prop="jstime">
									<el-date-picker v-model="ruleForm.jstime" type="datetime"
										value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择会议结束时间">
									</el-date-picker>
								</el-form-item>
							</div>
						</el-form-item>
						<el-form-item label="会议详情" prop="content">
							<el-input type="textarea" :autosize="{ minRows: 7, maxRows: 7}"
								v-model="ruleForm.content"></el-input>
						</el-form-item>
						<el-form-item label="打卡范围(米)" prop="fw">
							<el-input v-model="ruleForm.fw" type="number" style="width: 220px;"></el-input>
						</el-form-item>
						<el-form-item label="最大参加人数">
							<el-input v-model="ruleForm.max" type="number" style="width: 220px;"></el-input>
						</el-form-item>
						<el-form-item label="选择参会人员" required="">
							<div style="display: flex;align-items: center;">
								<el-button type="primary" size="small" @click="select">选择</el-button>
							</div>
							<div>
								<span style="color: #999;">已选人员：</span>{{user_name}}
							</div>
						</el-form-item>
						<el-form-item label="选择会议室">
							<div style="display: flex;align-items: center;">
								<div style="margin-right: 10px;">
									{{ruleForm.address}}
								</div>
								<el-button type="primary" size="small"
									@click="showroom">{{ruleForm.address?'重新选择':'选择会议室'}}</el-button>
							</div>
						</el-form-item>
						<el-form-item label="地图坐标" prop="zuobiao" style="width: 520px;">
							<el-input v-model="ruleForm.zuobiao" placeholder="输入经纬度,示例:116.37,40.31">
								<template slot="append">
									<el-button type="primary" @click="openmap">打开地图</el-button>
								</template>
							</el-input>
						</el-form-item>
						<el-form-item label="短信通知">
							<div>
								<el-switch v-model="ruleForm.dxkg" :active-value="1" :inactive-value="0">
								</el-switch>
							</div>

						</el-form-item>
						<el-form-item label="报名开关">
							<div>
								<el-switch v-model="ruleForm.bmkg" :active-value="1" :inactive-value="0">
								</el-switch>
							</div>
						</el-form-item>
					</el-form>

				</div>

				<div style="display: flex;align-content: center;margin-top: 30px;">
					<el-button @click="backpage">取 消</el-button>
					<el-button type="primary" @click="submitForm">确 定</el-button>
				</div>
			</div>
		</div>


		<selectusers ref="myselectuser" @selectedry="selectedry"></selectusers>

		<selectroom ref="sleroom" @seletedroom="seletedroom"></selectroom>
	</div>

</template>

<script>
	import selectusers from '@/components/select_users.vue'
	import selectroom from './components/selectroom.vue'
	export default {
		components: {
			selectusers,
			selectroom
		},
		data() {
			return {
				baseurl: "",
				ruleForm: {
					name: '',
					invest_research_id: '',
					kstime: '',
					endtime: '',
					addtime: '',
					dqtime: '',
					qdtime: '',
					jstime:'',
					content: '',
					chuser_ids: '',
					chuser_name: '',
					address: '',
					room_id: '',
					zuobiao: '',
					fw: '',
					max: '',
					dxkg: '',
					bmkg: '',
				},
				rules: {
					name: [{
						required: true,
						message: '请输入会议标题',
						trigger: 'blur'
					}],
					invest_research_id: [{
						required: true,
						message: '请选择调研活动',
						trigger: 'change'
					}],
					kstime: [{
						required: true,
						message: '请选择报名开始时间',
						trigger: 'change'
					}],
					endtime: [{
						required: true,
						message: '请选择报名截止时间',
						trigger: 'change'
					}],
					addtime: [{
						required: true,
						message: '请选择会议开始时间',
						trigger: 'change'
					}],
					dqtime: [{
						required: true,
						message: '请选择会议结束时间',
						trigger: 'change'
					}],
					qdtime: [{
						required: true,
						message: '请选择签到开始时间',
						trigger: 'change'
					}],
					jstime: [{
						required: true,
						message: '请选择签到结束时间',
						trigger: 'change'
					}],
					content: [{
						required: true,
						message: '请填写会议详情',
						trigger: 'blur'
					}],
					fw: [{
						required: true,
						message: '请填写打卡范围',
						trigger: 'blur'
					}],
				},
				hyytlist: [], //会议议题数据
				user_id: '',
				user_name: '',
			}
		},
		created() {
			this.gethyytlist() //会议议题数据
		},
		methods: {
			submitForm() {
				if(!this.user_id){
					this.$message.warning('请选择参会人员')
					return
				}
				if(!this.ruleForm.address&&!this.ruleForm.zuobiao){
					this.$message.warning('请选择会议室或录入地图坐标')
					return
				}
				this.$refs.ruleForm.validate((valid) => {
					if (valid) {
						this.ruleForm.chuser_ids = this.user_id
						this.ruleForm.chuser_name = this.user_name
						this.$post({
							url: '/api/invest_research_wymeeting/add',
							params: this.ruleForm
						}).then((res) => {
							this.$message.success('添加成功')
							this.$router.go(-1)
						})
					} else {
						console.log('error submit!!');
						return false;
					}
				})
			},
			openmap() {
				window.open('https://api.map.baidu.com/lbsapi/getpoint/index.html', '_blank')
			},
			seletedroom(e) {
				this.ruleForm.address = e.addr
				this.ruleForm.room_id = e.id
			},
			showroom() { //选择会议室
				if (!this.ruleForm.addtime) {
					this.$alert('请先选择会议开始时间', '提示', {
						confirmButtonText: '确定',
						callback: action => {

						}
					})
					return;
				}
				this.$refs.sleroom.radiovalue1 = this.ruleForm.room_id
				this.$refs.sleroom.addr = this.ruleForm.address
				this.$refs.sleroom.dialogVisible = true
				this.$refs.sleroom.starttime = this.ruleForm.addtime
				this.$refs.sleroom.getlist()
			},
			select() {
				let cxidlist = this.user_id ? this.user_id.split(',') : []
				let cxnamelist = this.user_name ? this.user_name.split(',') : []
				let list = []
				if (cxidlist.length > 0) {
					cxidlist.forEach((item, index) => {
						let obj = {
							name: cxnamelist[index],
							id: Number(item)
						}
						list.push(obj)
					})
				}
				this.$refs.myselectuser.selectuser = list.length > 0 ? list : []
				this.$refs.myselectuser.dialogVisible = true
			},
			selectedry(e) {
				this.user_id = e.map((item) => {
					return item.id
				})
				this.user_id = this.user_id.join(',')
				this.user_name = e.map((item) => {
					return item.name
				})
				this.user_name = this.user_name.join(',')
			},
			gethyytlist() {
				this.$post({
					url: '/api/invest_research/index',
					params: {
						name:'',
						p: 1,
						size: 999
					}
				}).then((res) => {
					this.hyytlist = res.list
				})
			},
			backpage(){
				this.$router.go(-1)
			}
		}
	}
</script>

<style lang="scss" scoped>
	.box_main {
		background-color: #fff;
		padding: 15px 55px;
		width: 100%;
		box-sizing: border-box;
		min-height: 100%;
	}

	.search_box {
		display: flex;
		align-items: center;
		font-size: 0.16rem;

		.search_item {
			display: flex;
			align-items: center;
			margin-right: 0.5rem;

		}
	}

	.box_cont {
		margin-top: 20px;
	}

	.btn_list {
		display: flex;
		align-items: center;
	}

	.tc_title {
		font-size: 0.18rem;
		font-weight: bold;
		color: #333;
		position: relative;
		padding-left: 20px;
		margin-bottom: 20px;
	}

	.tc_title:after {
		content: '';
		position: absolute;
		height: 100%;
		width: 4px;
		left: 0px;
		background-color: rgb(26, 144, 253);
	}

	::v-deep td {
		text-align: center !important;
	}

	::v-deep .el-table th.el-table__cell>.cell {
		text-align: center !important;
	}

	::v-deep .el-table th.el-table__cell.is-leaf {
		background-color: rgb(242, 242, 242);
		color: #777;
	}

	::v-deep .el-table th.el-table__cell.is-leaf,
	.el-table td.el-table__cell {
		border-bottom: none !important;
	}

	::v-deep .el-table td.el-table__cell {
		border-bottom: none !important;
	}

	::v-deep .el-table {
		margin-top: 10px;
		border-left: none !important;
	}

	::v-deep .el-table__fixed {
		border-bottom: none;
	}

	::v-deep .el-table::before {
		background-color: #fff !important;
	}

	::v-deep .el-table__fixed::before {
		background-color: #fff !important;
	}

	::v-deep .el-dialog__header {
		display: flex !important;
	}

	::v-deep .el-dialog__footer span {
		display: flex !important;
		justify-content: space-around;
		width: 100%;
	}

	::v-deep .el-form-item__content {
		display: flex;
		flex-direction: column;
	}

	::v-deep .el-form-item__content {
		margin-left: 0 !important;
	}

	::v-deep .el-table__fixed-right::before {
		background-color: #fff !important;
	}

	.item_class {
		display: flex;
		align-items: center;
		width: 100%;
		box-sizing: border-box;

		.el-form-item {
			margin-right: 150px;
		}
	}

	::v-deep.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>